
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
// TODO: Remove the breakpoints as all the sizes and ratios are the same for all the breakpoints and it's coming from CMS

/**
  * small sizes - 140px min-width - 8.75rem
  * large sizes - 240px min-width - 15rem
  * Ratios: 1:1, 4:3, 4:5
  * height: auto; So the aspect ratio calculates the height based on the width
  * width: 100%; So the container fills the width based on the flex container
  * small screens - gap between images set to 8px / 0.5rem
  * large screens - gap between images set to 16px / 1rem
  * Images border have been removed as it's not needed
*/

@mixin imgcover {
  object-fit: cover;
}

.merchandising {
  display: inline-block;
  text-align: center;
}

.merchandising__img__container {
  display: inline-block;
}

.merchandising__a {
  display: block;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;

  &:hover img {
    opacity: 0.8;
  }
}

.merchandising__p {
  font-family: HelveticaNowText, sans-serif;
  font-size: 0.75rem;
  line-height: 1.063rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  padding-top: 0.5rem;
  margin: 0;
  letter-spacing: 0.094rem;
  color: rgb(43, 43, 43);
  box-sizing: border-box;
}

.merchandising__img {
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
  box-sizing: content-box;
  @include imgcover;
  min-width: 8.75rem;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.merchandising__img--4_3 {
  height: auto;
  max-height: inherit;
  aspect-ratio: 4 / 3;

  &.merchandising__img--slider_large {
    min-height: auto;
  }
}

.merchandising__img--4_5 {
  height: auto;
  max-height: inherit;
  aspect-ratio: 4 / 5;
}

.merchandising__img--slider_large {
  min-width: 15rem;
}

@include respond-to($sm-up) {
  .merchandising {
    display: flex;
    flex-direction: row;
  }
}

@include respond-to($md-up) {
  .merchandising {
    display: flex;
    flex-direction: row;
    width: auto;
    max-width: 13.75rem;
    text-align: center;
  }

  .merchandising__p {
    font-size: 0.875rem;
    padding-top: 1.438rem;
  }
}

@include respond-to($lg-up) {
  .merchandising {
    width: 100%;
  }
}

@include respond-to($xl-up) {
  .merchandising-6 {
    width: 100%;
  }
}
