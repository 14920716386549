
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.line {
  flex-grow: 1;
  margin-right: 1.5rem;
  height: 0.125rem;
  background-color: #ebebeb;
  border-radius: 0.625rem;
  align-self: center;
  position: relative;
  @include respond-to($md-up) {
    max-width: 330px;
  }
  &__active {
    position: absolute;
    height: 100%;
    border-radius: 0.625rem;
  }
}
