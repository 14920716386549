
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
$hoverColor: var(--chipsField-hoverColor, rgba(30, 30, 30, 0.03));
$activeColor: var(--chipsField-activeColor, rgba(30, 30, 30, 0.05));

.chip {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  line-height: 1.25rem;
  white-space: nowrap;
  display: inline-block;
  max-width: 17.5rem;
  min-width: 4.5rem;

  &__lines {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  span,
  span > * {
    @extend .chip__lines;
    text-align: center;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    display: block !important;
  }

  &:focus-visible {
    outline: $color-focus-accessibility solid 1px;
    outline-offset: -1px;
  }

  &:hover {
    background: $hoverColor;
  }

  &:active {
    background: $activeColor;
  }
}

@include respond-to($md-up) {
  .chip {
    font-size: 1rem;
    line-height: 1.5;
  }
}
