
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.accordion-container {
  width: 100%;
  padding: 0.5rem 1rem;

  .accordion-inner {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 48px;
    position: relative;
    padding-right: 20px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 6px;
      height: 6px;
      border-bottom: solid 1px #282828;
      border-left: solid 1px #282828;
      transition: all 0.3s ease;
    }

    &__open:after {
      transform: translateY(-50%) rotate(-225deg);
    }

    &__close:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  .accordion-icon-container {
    min-width: 2.5rem;
  }

  .accordion-icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & > * {
      width: 1.25rem;
    }
  }

  .accordion-title {
    font-size: 0.875rem;
  }

  .accordion-content {
    width: 100%;
    overflow: hidden;
    background-color: transparent;
    font-size: 0.875rem;
    transition: all 0.3s ease;
    padding: 0.5rem 1.125rem 0.75rem 2.5rem;
    box-sizing: border-box;
    max-height: 350px;

    &__open {
      opacity: 1;
    }
    &__close {
      opacity: 0;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
