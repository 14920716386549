
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.btnlist {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-bottom: 1.5rem;
  margin-right: -1.5rem;
  overflow-x: scroll;
  padding-right: 1.5rem;
  &::-webkit-scrollbar {
    display: none;
  }

  & > .buttonWrap {
    flex-grow: 1;
    width: 100%;
    height: 2.5rem;
    text-align: center;
    padding-left: 0.5rem;

    &:first-child {
      padding-left: 0;
    }
    & > button {
      width: 100%;
    }
  }
}

.deleteIcon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.sizeTypeTitle {
  margin-bottom: 0.75rem;
  font-weight: bold;
}

.interests {
  padding: 0 0 1rem 0;
  border-bottom: 1px solid #ebebeb;
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
}
.moreIcon {
  width: 1rem;
  position: absolute;
  height: 1rem;
  top: 0;
  left: 0;
  cursor: pointer;
  display: inline-block;
  margin: -1.1rem 0 0 -2rem;
  padding: 1.25rem;
  box-sizing: content-box;

  &--webview {
    left: unset;
    right: 0;
  }
}

.delete {
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 8%), 0px -1px 1px rgb(0 0 0 / 8%), 1px 0px 1px rgb(0 0 0 / 8%),
    -1px 0px 1px rgb(0 0 0 / 8%);
  transform-origin: 0 0;
  width: auto;
  background-color: #fff;
  font-size: 0.75rem;
  padding: 1rem;
  color: #ff3b30;
  cursor: pointer;
  display: none;
  z-index: 1;
  border-radius: 0.25rem;
  &.show {
    display: block;
  }
  // white-space: ;
}

.stepsContainer {
  margin: 0 1.5rem;
}

.sizesContainter {
  margin-bottom: 2rem;
}

.toggleBtnContainer {
  margin-bottom: 1.125rem;
}

.sizes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(3.875rem, 1fr));
  gap: 0.5rem;
  width: 100%;
  margin: 1rem auto 0 auto;
  & > div {
    width: auto;
  }
}

.footerStyleOverwrite {
  & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.checked {
  border: 1px solid #1e1e1e;
  // background: #1e1e1e;
  // color: #fff;
}
.unchecked {
  border: 1px solid #dedede;
  // background: 0 0;
  // color: #1e1e1e;
}

.textSwatchOverwrite {
  color: #1e1e1e;
  border-radius: unset;
  &:hover {
    background: #f5f6f8;
    color: #1e1e1e;
  }

  &[class*='_checked_'] {
    border-color: #1e1e1e;
    font-weight: bold;
    background: #fff;
  }
}
.universeTab {
  border-radius: unset;
}

@include respond-to($md-up) {
  .sizeTypeTitle {
    font-size: 1rem;
  }

  .sizes {
    grid-template-columns: repeat(auto-fit, minmax(4.8125rem, 1fr));
  }
}

@include respond-to($sm-down) {
  .editingDialog {
    padding: 1rem 0;

    & > div {
      position: relative;
      max-width: 100vw;
      overflow: scroll;
      padding-bottom: 8rem;

      & > button[class*='vc-modal__closeBtn'] {
        top: 0;
      }
    }
  }

  .moreIcon {
    margin: -1.1rem 0.5rem 0 0rem;
  }
}
