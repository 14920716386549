
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@import '~@vestiaire/design-system/scss/_variables';

.textSwatch {
  display: flex;
  padding-top: 2px;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 2.25rem;
  font-size: 0.875rem;
  border: 1px solid $vc-color-g20;
  background: #fff;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s;

  &:hover {
    border: 1px solid $vc-color-g100;
  }

  &.checked {
    border: 2px solid $vc-color-g100;
    font-weight: 700;
  }
}
