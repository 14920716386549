
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.respContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 26.625rem;
  height: 100%;
  margin: auto;
  text-align: center;
  padding: 11rem 2.5rem 14rem;
}

.respStatusContainer {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
}
.respIcon {
  color: #34c759;

  &.error {
    color: #ff3b30;
  }
}

.respTitle {
  text-align: center;
  margin-top: 0.5rem;
  font-weight: bolder;
  font-size: 1.125rem;
}

.respContent {
  text-align: center;
  margin-top: 0.5rem;
}

.respButton {
  text-align: center;
  margin-top: 1.5rem;
}

.loadingContainer {
  height: 30vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.feedbackBtn {
  height: 2.5rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.125rem;
  font-weight: 700;
  width: 8rem;
}
.loadingContainerWrap {
  position: relative;
}
