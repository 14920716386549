
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.textcontainer {
  width: 100%;
  padding: 24px 4px;
  text-align: center;
  display: flex;
  justify-content: center;

  &_text_module {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  &_title {
    text-align: center;
    margin-bottom: 8px;
  }

  .main_text {
    text-align: center;
    margin: 8px 0;

    ol,
    ul {
      list-style: disc;
    }

    em {
      font-style: italic;
    }
  }

  &_cta_module {
    padding: 16px 0 0;

    &_cta {
      justify-content: center;
      min-width: 268px;
      margin-bottom: 1rem;

      a {
        width: 100%;
      }
    }
  }
}

@include respond-to($md-up) {
  $padding: 32px;

  .textcontainer {
    padding: $padding 0;
    display: flex;

    &_text_module {
      width: 872px;
      padding: 0 $padding;
    }

    &_cta_module {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &_cta {
        display: flex;
        justify-content: center;
        width: auto;
        min-width: auto;

        a {
          width: 100%;
        }
      }
    }

    .textcontainer_cta_module_cta + .textcontainer_cta_module_cta {
      margin-left: 1rem;
    }
  }
}

@include respond-to($lg-up) {
  .textcontainer {
    &_text_module {
      width: 872px;
      padding: 0;
    }
  }
}
