
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
$padding: 24px;

.title {
  text-align: center;
  margin-bottom: 1rem;
}

.main_text {
  text-align: center;
  padding-bottom: $padding;

  ol,
  ul {
    list-style: disc;
  }

  em {
    font-style: italic !important;
  }
}

@include respond-to($md-up) {
  $padding: 32px;

  .title {
    text-align: left;
  }

  .main_text {
    text-align: left;
  }
}

@include respond-to($lg-up) {
  .main_text {
    text-align: left;
  }
}
