
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.product_general_component {
  .product_row {
    &__catalog {
      .products_container__resultContainer {
        section {
          padding: 1.5rem 0;
        }

        h2 {
          line-height: 1.2;
          font-size: 1.5rem;
        }

        h2 + div > div {
          padding-left: 0;
        }
        &__subtitle {
          padding-left: 1.25rem;
          color: $vc-color-black-light;
          span {
            font-size: 1rem;
            &:first-child {
              margin-right: 0.4rem;
            }
          }
          &__button {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &__loadingContainer {
        display: flex;
        flex-wrap: wrap;
        min-height: 25rem;
        margin: auto 0.5rem;

        & > li {
          flex: 0 0 50%;
          padding: 0.5rem;
          width: 50%;

          @include respond-to($lg-up) {
            padding-top: 10rem;
            flex: 0 0 25%;
          }

          @include respond-to($sm-only) {
            margin: 0;
            padding: 0.25rem;
          }
        }
      }
    }
  }
}

@include respond-to($md-up) {
  .product_general_component {
    .product_row {
      display: block;

      .products_container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        &__resultContainer {
          margin-bottom: 1rem;
          &__subtitle {
            padding-bottom: 0.5rem;
            padding-left: 1.75rem;
            span {
              font-size: 1.125rem;
            }
          }

          section {
            padding: 2.5rem 0;
          }

          h2 {
            line-height: 1.2;
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}

@include respond-to($sm-down) {
  .product_general_component {
    .product_row {
      .products_container {
        &__loadingContainer {
          margin: 0;

          li:nth-last-child(-n + 2) {
            display: none;
          }
        }

        &__resultContainer {
          &__subtitle {
            padding-bottom: 0.25rem;
            margin-top: -0.125rem;
          }
          section {
            padding: 1.5rem 0;
          }

          h2 {
            line-height: 1.2;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@include respond-to($md-up) {
  .product_general_component {
    .product_row {
      .products_container {
        &__loadingContainer {
          & > li {
            flex: 0 0 33.3%;
            padding: 0.5rem;
            width: 33.3%;
          }

          li:nth-last-child(-n + 1) {
            display: none;
          }
        }
      }
    }
  }
}

@include respond-to($lg-up) {
  .product_general_component {
    .product_row {
      .products_container {
        &__loadingContainer {
          margin: 0 0.75rem;

          & > li {
            flex: 0 0 25%;
            padding: 0.5rem;
            width: 25%;
          }

          li:nth-last-child(-n + 1) {
            display: block;
          }
        }
      }
    }
  }
}
