
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.step {
  width: 100%;
  height: calc(100% + 100px);

  .titleContainer {
    text-align: center;
    margin-bottom: 2rem;

    & .title {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    & .subTitle {
      color: #656565;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .sizesContainter {
    padding: 0 1.5rem;
    margin-bottom: 2rem;

    &__accordion {
      max-width: 503px;
      margin: auto;
    }
  }

  .sizes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3.875rem, 1fr));
    gap: 0.5rem;
    width: 100%;
    max-width: 26.25rem;
    margin: 1rem auto 0 auto;
    & > div {
      width: auto;
    }
  }

  .stepFigureContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .stepFigure {
      display: flex;
      width: 4rem;
      height: 4rem;
      padding: 0.875rem;
      border-radius: 50%;
      background-color: #f5f6f8;
      justify-content: center;
      align-items: center;
    }

    .stepImage {
      width: 3rem;
    }
  }

  .firstStep {
    padding-bottom: 1rem;

    @include respond-to($md-up) {
      padding-bottom: 6rem;
    }
  }

  .footer {
    border-top: 1px solid #ebebeb;

    .footerContent {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .checked {
    border: 1px solid #1e1e1e;
    background: #1e1e1e;
    color: #fff;
  }
  .unchecked {
    border: 1px solid #dedede;
    background: 0 0;
    color: #1e1e1e;
  }
}

.textSwatchOverwrite {
  color: #1e1e1e !important;
  border-radius: unset !important;

  &[class*='_checked_'] {
    border-color: #1e1e1e;
    font-weight: bold;
    background: #fff;
  }

  &:hover {
    background: #f5f6f8;
    color: #1e1e1e;
  }
}

@include respond-to($md-up) {
  .step {
    .container {
      flex-direction: row;
    }
    .sizes {
      grid-template-columns: repeat(auto-fit, minmax(4.8125rem, 1fr));
    }
  }
}
