
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.campaignHero--voucher {
  padding: 0;
  margin-bottom: 0.5rem;

  @include respond-to($md-up) {
    padding: 1.25rem;
    margin-bottom: 0;
  }

  @include respond-to($xl-up) {
    padding: 1.5rem 0;
  }
}
