
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.voucher {
  &__lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .voucher_container {
    margin: 1.5rem 0;
    &__title {
      font-family: Albra-Regular;
      line-height: 1.2;
      font-size: 2rem;
      @extend .voucher__lines;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
    }

    &__subtitle {
      font-family: HelveticaNowText, sans-serif;
      font-size: 1.125rem;
      padding-top: 0.25rem;
      @extend .voucher__lines;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    &__cta {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: 0.75rem;
      &__text {
        font-family: HelveticaNowText, sans-serif;
        font-weight: 500;
        position: relative;
        @extend .voucher__lines;
        -webkit-line-clamp: 1;
        line-clamp: 1;
      }

      &__button {
        display: inline-block;
      }
    }

    &__arrow {
      display: inline-block;
      position: relative;
      padding-left: 0.25rem;
      width: 28px;
      height: 26.4px;
      padding-top: 0.4rem;
    }
  }
}

@include respond-to($sm-down) {
  .voucher {
    padding: 0;
    .voucher_container {
      padding: 1rem 1.5rem;
      margin: 0 0 0.5rem 0;
      &__arrow {
        width: 24px;
        height: 22.4px;
      }
      &__title {
        font-size: 1.5rem;
      }
      &__subtitle {
        font-size: 1rem;
        padding-top: 0.125rem;
      }
      &__cta {
        padding-top: 0.5rem;
        &__text {
          font-size: 1rem;
        }
      }
    }
  }
}

@include respond-to($md-up) {
  .voucher {
    .voucher_container {
      padding: 1.5rem;
      margin: 1.25rem 0;
      &__title {
        font-size: 1.75rem;
      }
      &__subtitle {
        font-size: 1rem;
        padding-top: 0.125rem;
      }
      &__cta {
        padding-top: 0.25rem;
        &__text {
          font-size: 1rem;
        }
      }
    }
  }
}

@include respond-to($lg-up) {
  .voucher {
    .voucher_container {
      padding: 1.5rem 2.5rem;
      margin: 1.5rem 0;
      &__title {
        font-size: 2rem;
      }
      &__subtitle {
        font-size: 1rem;
        padding-top: 0.25rem;
      }
      &__cta {
        padding-top: 0.5rem;
        &__text {
          font-size: 1rem;
        }
      }
    }
  }
}

@include respond-to($xl-up) {
  .voucher {
    padding: 0;
    .voucher_container {
      &__title {
        font-size: 2rem;
      }
      &__subtitle {
        font-size: 1.125rem;
        padding-top: 0.25rem;
      }
      &__cta {
        &__text {
          font-size: 1.125rem;
        }
      }
    }
  }
}
