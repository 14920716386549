
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.product_feed {
  &__flexContainer {
    display: flex;
    flex-wrap: wrap;

    &--item {
      flex: 0 0 50%;
      width: 50%;
      outline: solid 1px $vc-color-g30;

      & > div {
        max-width: 100%;
      }
      &:nth-child(5n) {
        display: none;
      }

      .productCardContainer {
        height: 100%;
      }
    }
  }
}

@include respond-to($md-up) {
  .product_feed {
    &__column {
      flex: 4 0 0;
    }

    &__flexContainer {
      &--item {
        flex: 0 0 25%;
        max-width: 25%;
        &:nth-child(5n) {
          display: none;
        }
      }
    }
  }
}

@include respond-to($lg-up) {
  .product_feed {
    &__flexContainer {
      &--item {
        flex: 0 0 20%;
        max-width: 20%;
        box-sizing: border-box;
        &:nth-child(5n) {
          display: flex;
        }
      }
    }
  }
}
