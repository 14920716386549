
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.productBlockSection {
  padding: 1.5rem 0;
  background: $vc-color-white-off;

  &--small {
    padding: 1rem 0;
    background: white;
    border-bottom: solid 1px #e8e8e8;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.5rem;
  }
  &__title {
    line-height: 1.25rem;
    font-family: $vc-font-headings;
    font-size: 1.5rem;

    &--small {
      font-family: $vc-font-body;
      font-size: 0.625rem !important;
      line-height: 0.875rem !important;
      color: $vc-color-g60;
      text-transform: uppercase;
    }
  }

  &__subtitle {
    margin: 0.125rem 0 0.25rem 0;
    color: $vc-color-g70;

    a {
      text-decoration: underline;
    }
  }

  &__cta {
    margin: 1.25rem 0 0 0;
    border-radius: 2rem;
  }
}

@include respond-to($md-up) {
  .productBlockSection {
    padding: 2.5rem 0;

    &--small {
      padding: 1rem 0;
    }

    &__header {
      margin-bottom: 1rem;

      &--small {
        margin-bottom: 0.5rem;
      }
    }

    &__title {
      line-height: 1.2;
      font-size: 2.5rem;
    }

    &__subtitle {
      margin: 0;
      margin-bottom: -0.5rem;
    }

    &__cta {
      margin: 0 0 0 auto;
    }
  }
}
