.gradient {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -15%);
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #d6d5d5 0%, #fcfcfc 50%, #d6d5d5 100%);
  background-size: 400% 400%;
  animation: pulse 1.85s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
