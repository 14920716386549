
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.campaignHeroFields {
  display: flex;
  justify-content: space-between;

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &--voucher {
    gap: 0.5rem;
  }

  &--marketing_medium {
    flex-direction: column;
    gap: 1rem;
  }

  &--marketing_small {
    gap: 0rem;
  }

  &__container {
    &--full,
    &--marketing_medium {
      flex: 1;
      order: 2;
    }

    &--marketing_small {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include respond-to($md-up) {
    &--marketing_medium,
    &--marketing_small {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &--marketing_medium {
      gap: 1.5rem;
    }

    &__container {
      &--marketing_small {
        // flex: 1; // TODO: Temporarily removed
      }
    }
  }
  @include respond-to($lg-up) {
    &--marketing_medium {
      gap: 2.5rem;
    }
  }
}
