
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
$ctaFieldHoverColor: var(--ctaField-hoverColor, rgba(30, 30, 30, 0.03));
$ctaFieldActiveColor: var(--ctaField-activeColor, rgba(30, 30, 30, 0.05));
$textColor: var(--textColor);
$textAlignment: var(--textAlignment);

.field {
  transition: all ease 0.1s;
  & em,
  & i {
    font-style: italic;
  }

  &__lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  & span,
  & button,
  & span > * {
    @extend .field__lines;
    text-align: center;
  }

  &--tagField {
    margin-top: var(--tagField-margin);
    padding: 0 1.5rem;
    width: 100%;

    & span,
    span > * {
      line-height: normal;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }
  }

  &--titleField {
    margin-top: var(--titleField-margin);
    padding: 0 1.5rem;
    width: 100%;

    & span,
    span > * {
      line-height: normal;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }

    &--voucher,
    &--marketing_medium,
    &--marketing_small {
      margin-top: 0;

      & span,
      & button,
      & span > * {
        @extend .field__lines;
        line-height: 1.2;
        text-align: left;
        word-break: break-word;
      }
    }

    &--marketing_medium {
      padding: 0;
    }

    &--marketing_small {
      margin-top: var(--titleField-margin);
      padding: 0 1.5rem;

      & span,
      span > * {
        line-height: normal;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }

    &--merch {
      padding: 0;
      margin-top: 0.5rem;
      & span,
      span > * {
        text-align: $textAlignment;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }
  }

  &--subtitleField {
    margin-top: var(--subtitleField-margin);
    padding: 0 1.5rem;
    width: 100%;

    & span,
    span > * {
      line-height: 1.5;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    &--voucher,
    &--marketing_medium,
    &--marketing_small {
      margin-top: 0.125rem;

      & span,
      & button,
      & span > * {
        @extend .field__lines;
        text-align: left;

        @include respond-to($md-up) {
          font-size: 1rem;
        }
        @include respond-to($xl-up) {
          font-size: 1.125rem;
        }
      }
    }

    &--marketing_medium {
      padding: 0;
    }

    &--marketing_small {
      margin-top: 0.25rem;
      padding: 0 1.5rem;
    }

    &--merch {
      padding: 0;
      margin: 0;
    }
  }

  &--ctaField {
    margin-top: 24px;
    border-color: $textColor;
    padding: 0 1.5rem;

    & button {
      max-width: 17.5rem;
      min-width: 4.5rem;
    }

    & button,
    span,
    span > * {
      line-height: 1.2;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      display: block;
    }

    &--voucher,
    &--marketing_medium,
    &--marketing_small {
      margin-top: 0.5rem;
      width: 100%;

      & span,
      & button,
      & span > * {
        @extend .field__lines;
        text-align: left;
      }
    }

    &--marketing_medium {
      padding: 0;
    }

    &--marketing_small {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      padding: 0 1.5rem;
    }

    &-button {
      border-color: $textColor;
      color: $textColor;
      background-color: var(--ctaField-backgroundColor);

      &:hover {
        background: $ctaFieldHoverColor;
      }

      &:active {
        background: $ctaFieldActiveColor;
      }
    }
  }

  &--chipsField {
    margin-top: var(--chipsField-margin);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    border-color: $textColor;
    overflow-x: auto;

    // Hides the scrollbar for firefox
    scrollbar-width: none;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      display: none;
    }

    /*
      This <container> is utilized to address flexbox centering issues
      when the flex item is larger than the flex container. It employs flexbox auto margins
      instead of using 'justify-content' for the children.
    */
    &__container {
      margin: auto;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 1.5rem;
    }
  }

  &__voucherCtaField {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__text {
      font-family: HelveticaNowText, sans-serif;
      font-weight: 500;
      position: relative;
      @extend .field__lines;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      @include respond-to($md-up) {
        font-size: 1rem;
      }
      @include respond-to($xl-up) {
        font-size: 1.125rem;
      }
    }

    &__button {
      display: inline-block;
    }
    &__arrow {
      display: inline-block;
      position: relative;
      padding-left: 0.25rem;
      width: 24px;
      height: 22.4px;
    }
  }

  &--iconField {
    padding: 0 1rem;

    & svg {
      height: 20px;
      width: 20px;
    }
  }

  &--imageField {
    height: auto;
    &--marketing_medium {
      object-fit: cover;
      aspect-ratio: 16 / 9;
    }

    &--marketing_small {
      width: 128px;
      height: 160px;
      object-fit: cover;
      aspect-ratio: 4 / 5;
    }
  }

  @include respond-to($md-down) {
    &--titleField,
    &--subtitleField,
    &--ctaField {
      &--voucher {
        padding-right: 0;
      }
      &--merch__small {
        margin-top: 0.5rem;
      }
      &--merch {
        margin-top: 0.75rem;
        & span,
        span > * {
          text-align: $textAlignment;
        }
      }
    }

    &--subtitleField {
      &--merch {
        margin-top: 2px;
      }
    }

    &--iconField {
      padding-left: 0rem;
    }
  }

  @include respond-to($md-up) {
    &--tagField,
    &--titleField,
    &--subtitleField,
    &--ctaField {
      padding: 0 2.5rem;

      &--voucher {
        padding: 0 1.5rem;
      }

      &--marketing_medium {
        padding: 0;
      }

      &--marketing_small {
        padding: 0 1.5rem;
      }

      &--merch__small {
        margin-top: 0.5rem;
      }
      &--merch {
        margin-top: 0.75rem;
        & span,
        span > * {
          text-align: $textAlignment;
        }
      }
    }

    &--titleField {
      &--marketing_small {
        margin-top: 1.5rem;
      }

      &--merch__small {
        margin-top: 0.5rem;
      }
      &--merch {
        padding: 0;
        margin-top: 0.75rem;
        & span,
        span > * {
          text-align: $textAlignment;
        }
      }
    }

    &--ctaField {
      &--voucher {
        margin-top: 0.5rem;
      }

      &--marketing_medium {
        margin-top: 1rem;
      }

      &--marketing_small {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    &--subtitleField {
      &--voucher,
      &--marketing_medium {
        margin-top: 0.25rem;
      }

      &--merch {
        padding: 0;
        margin-top: 2px;
        & span,
        span > * {
          text-align: $textAlignment;
        }
      }
    }

    &__voucherCtaField {
      &__arrow {
        width: 28px;
        height: 26.4px;
      }
    }

    &--iconField {
      padding: 0 1.5rem;

      & svg {
        height: 24px;
        width: 24px;
      }
    }

    &--imageField {
      &--marketing_small {
        width: 192px;
        height: 240px;
        aspect-ratio: 4 / 5;
      }
    }
  }

  @include respond-to($lg-up) {
    &--tagField,
    &--titleField,
    &--subtitleField,
    &--ctaField {
      &--voucher {
        padding: 0 2.5rem;
      }

      &--marketing_medium {
        padding: 0;
      }

      &--marketing_small {
        padding: 0 2.5rem;
      }

      &--merch__small {
        margin-top: 0.5rem;
      }
      &--merch {
        margin-top: 0.75rem;
        & span,
        span > * {
          text-align: $textAlignment;
        }
      }
    }

    &--subtitleField {
      &--merch {
        margin-top: 2px;
      }
    }

    &--ctaField {
      &--voucher {
        margin-top: 0.75rem;
        width: 100%;
      }

      &--marketing_medium {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
}

.contextualModal {
  padding: 1.5rem !important;

  @include respond-to($md-up) {
    padding: 2.5rem !important;
  }
}
