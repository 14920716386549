
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.popup {
  z-index: $modal-depth;
  padding: 2.5rem;
  background: white;
  &:focus {
    outline: none;
  }
}

.closeTxt {
  font-weight: bold;
  font-size: 0.75rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform-origin: right top;
  transform: translate(-2.8rem, -50%);
}

@include respond-to('(max-width:#{lower-bound($md-range)})') {
  .closeTxt {
    @include visuallyhidden;
  }

  .popup--mobileFullScreen {
    min-width: 100%;
    min-height: 100%;
    padding: 1rem;
  }
}
