
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 10.75rem;
  padding: 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 0.75rem;
  color: white;
  font-weight: bold;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(white, black);
  cursor: pointer;
  margin: 0 1.5rem 1rem 1.5rem;

  @include respond-to($md-up) {
    justify-content: flex-start;
    width: 209px;
    height: 209px;
    margin: 0 0.5rem;

    &:hover {
      .card__image {
        transform: scale(1.1);

        &::after {
          opacity: 1;
        }
      }
      .card__icon {
        margin-right: -0.5rem;
      }
    }
  }

  &__title {
    flex-grow: 1;
    display: inline-block;
    width: auto;
    padding-right: 1.5rem;
    z-index: 1;
  }

  &__image {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $dark-grey;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    transition: all 0.3s ease-in-out;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.75rem;
      opacity: 0.8;
      background: linear-gradient(210.66deg, rgba(0, 0, 0, 0) 25.9%, rgba(0, 0, 0, 1) 94.02%);
      transition: all 0.3s ease-in-out;
    }
  }

  &__icon {
    display: block;
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    min-height: 1rem;
    margin-left: 0.75rem;
    transform: rotate(180deg);
    transition: margin 0.3s;
    @include respond-to($md-up) {
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
