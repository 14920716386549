
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.add_my_sizes {
  &__wizard {
    display: block;

    &__backButtonAndPrograss {
      padding: 1.8rem 0;
      position: relative;

      &__withCloseBtn {
        padding-right: 1.8rem;
      }
    }

    &__backButtonContainer {
      position: absolute;
      padding: 1.4rem;
      left: 0;
      top: 0;
    }

    &__progressBarContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 3.5rem;

      &:only-child {
        padding: 0 0 0 1.5rem;
      }
    }

    &__backIcon {
      width: 1rem;
      height: 1rem;
    }
  }
}

.drawerStylefix {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@include respond-to($sm-down) {
  .creatingDialog {
    div[class*='vc-modal__innerContainer'] {
      padding: 0;
    }
  }
}
