
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.guideContainer {
  width: 100%;
}

@include respond-to($sm-down) {
  .drawerContainer {
    & > div {
      max-width: 100%;
      border-radius: 0 !important;
    }
  }
}

@include respond-to($md-up) {
  .drawerContainer {
    left: -2.5rem;
    top: -2.5rem;
    height: calc(100% + 2.5rem);
    width: calc(100% + 5rem);
    position: absolute;
  }
}
