
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@import '~@vestiaire/design-system/scss/_variables';

.appSheet {
  position: fixed;
  left: 0;
  bottom: -100%;
  background-color: white;
  width: 100%;
  padding: 0.5625rem 1.5rem 1.875rem 1.5rem;
  z-index: 2 * $modal-depth;
  border-radius: 1rem 1rem 0 0;
  font-size: 1rem;
  box-shadow: -0.05rem -0.005rem 0.25rem $vc-color-grey-dark;
  transition: bottom 0.4s ease-in-out;

  &__title {
    text-align: center;
    margin-bottom: 1.5rem;

    &.vc-text-xl {
      font-weight: bold;
    }
  }

  &--show {
    bottom: 0;

    &::before {
      content: '';
      display: block;
      width: 2.25rem;
      height: 0.25rem;
      margin: 0 auto 1rem auto;
      background-color: $vc-color-grey;
      border-radius: 0.875rem;
    }
  }

  &__overlay {
    display: none;

    &--show {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 2 * $modal-depth - 1;
    }
  }
}

@keyframes appear {
  to {
    opacity: 1;
  }
}
