
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.banner {
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    margin: auto;
    text-align: center;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &--on {
      animation-name: fromBasement;
    }

    &--off {
      top: 100%;
      animation-name: toCeiling;
    }
  }

  &__content {
    display: inline-block;
    padding: 0.8125rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

@include respond-to($md-up) {
  .banner {
    &__content {
      padding: 0.688rem 1.25rem 0.563rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

@keyframes toCeiling {
  from {
    top: 0;
    transform: translateY(0);
  }
  to {
    top: -100%;
    transform: translateY(-100%);
  }
}

@keyframes fromBasement {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}
