
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
$circle: 4.688rem;

.channel {
  height: 5.875rem;
  width: 4rem;
  text-align: center;
  margin: 0.5rem 0;

  &__img__container {
    display: block;
    width: 4rem;
    height: 4rem;
    margin: 0 auto;
  }

  &__a {
    display: block;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    box-sizing: border-box;
  }

  &__p {
    font-family: HelveticaNowText, sans-serif;
    font-size: 0.625rem;
    line-height: 0.75rem;
    font-weight: 400;
    text-align: center;
    padding-top: 0.375rem;
    margin: 0;
    letter-spacing: 0.025rem;
    word-spacing: 25vw;
    color: rgb(43, 43, 43);
    white-space: normal;
    box-sizing: border-box;
    word-break: keep-all;
  }

  &__img {
    white-space: nowrap;
    box-sizing: content-box;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
  }
}

@include respond-to($lg-up) {
  .channel {
    height: 6.688rem;
    width: 100%;
    text-align: left;
    display: flex;

    &__a {
      display: flex;
      align-items: center;

      .activeText {
        color: $vc-color-brand;
        font-weight: 500;
      }
    }
  }

  .active {
    .channel {
      &__img__container::before {
        content: '';
        display: block;
        width: calc($circle + 0.625rem);
        height: calc($circle + 0.625rem);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        border: $vc-color-brand solid 0.063rem;
      }

      &__p {
        color: $vc-color-brand;
      }
    }
  }

  .channel {
    &__img {
      &__container {
        width: $circle;
        height: $circle;
        margin: 0;
        position: relative;
      }
      white-space: nowrap;
      box-sizing: content-box;
      width: $circle;
      height: $circle;
      border-radius: 2.344rem;
      align-items: center;
    }

    &__p {
      align-items: center;
      text-align: left;
      line-height: normal;
      font-size: 0.938rem;
      word-spacing: 0;
      margin: 0 0 0 0.75rem;
    }
  }
}
