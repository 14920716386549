
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.product__block {
  background: $vc-color-white-off;
  &__title {
    padding: 1.5rem 0;
    color: $vc-color-g90;
    font-size: 1.5rem;

    &--hasSubtitle {
      padding-bottom: 0.25rem;
    }
  }

  .catalogPage {
    display: flex;
    flex-direction: column;

    &__catalog {
      display: block;
      background-color: #f5f6f8;
      &__subtitle {
        padding-left: 1.25rem;
        color: $vc-color-black-light;
        span {
          font-size: 1rem;
          &:first-child {
            margin-right: 0.4rem;
          }
        }
        &__button {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .catalog {
        &.innerContainer {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }

        &__flexContainer {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -0.25rem;

          &--item {
            flex: 0 0 50%;
            width: 50%;
            padding: 0.25rem;
          }
        }

        &__resultContainer {
          section {
            padding: 1.5rem 0;
          }
          h2 {
            line-height: 1.2;
            font-size: 1.5rem;
          }
          h2 + div > div {
            padding-left: 0;
          }
        }

        &__loadingContainer {
          display: flex;
          flex-wrap: wrap;
          min-height: 25rem;
          margin: auto 0.5rem;
          & > li {
            flex: 0 0 50%;
            padding: 0.5rem;
            width: 50%;

            @include respond-to($lg-up) {
              padding-top: 10rem;
              flex: 0 0 25%;
            }
            @include respond-to($sm-only) {
              margin: 0;
              padding: 0.25rem;
            }
          }
        }
      }
    }
  }
}

.product__block__bottom {
  text-align: center;
  padding: 0 0 1.5rem;
}

@include respond-to($md-up) {
  .product__block {
    &__title {
      padding: 2.5rem 0 0.5rem 0;
      font-size: 2.5rem;
      &--hasSubtitle {
        padding-bottom: 0.25rem;
      }
    }

    &__bottom {
      padding: 0 0 2.5rem;
    }

    .catalogPage {
      display: block;

      &__catalog {
        &__subtitle {
          padding-bottom: 0.5rem;
          padding-left: 1.75rem;
          span {
            font-size: 1.125rem;
          }
        }
      }
      .catalog {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        &.innerContainer {
          padding-right: 1.25rem;
          padding-left: 1.25rem;
        }
        &__columnProductList {
          flex: 4 0 0;
        }

        &__flexContainer {
          margin: 0;

          &--item {
            flex: 0 0 33.33%;
            padding: 0.5rem;
          }
        }

        &__resultContainer {
          margin-bottom: 1rem;
          section {
            padding: 2.5rem 0;
          }
          h2 {
            line-height: 1.2;
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}
@include respond-to($sm-down) {
  .product__block {
    &__bottom {
      padding-top: 1.25rem;
    }
    &__title {
      padding: 1.5rem 0 0.25rem 0;
    }
    .catalogPage {
      &__catalog {
        &__subtitle {
          padding-bottom: 0.25rem;
          margin-top: -0.125rem;
        }
      }
      .catalog {
        &__loadingContainer {
          margin: 0;

          li:nth-last-child(-n + 2) {
            display: none;
          }
        }
        &__resultContainer {
          section {
            padding: 1.5rem 0;
          }
          h2 {
            line-height: 1.2;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@include respond-to($md-up) {
  .product__block {
    .catalogPage {
      .catalog {
        &__loadingContainer {
          & > li {
            flex: 0 0 33.3%;
            padding: 0.5rem;
            width: 33.3%;
          }
          li:nth-last-child(-n + 1) {
            display: none;
          }
        }
      }
    }
  }
}

@include respond-to($lg-up) {
  .product__block {
    .catalogPage {
      .catalog {
        &__loadingContainer {
          margin: 0 0.75rem;
          & > li {
            flex: 0 0 25%;
            padding: 0.5rem;
            width: 25%;
          }
          li:nth-last-child(-n + 1) {
            display: block;
          }
        }
        &__flexContainer {
          &--item {
            flex: 0 0 25%;
          }
        }
      }
    }
  }
}
