
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.entriesWrapper {
  display: block;
  position: relative;
}

.baseline {
  display: none;
}

.channelContainer {
  padding: 0 0 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  overflow: auto;

  .entriesWrapper {
    display: flex;
    width: auto;

    .container__channel {
      padding: 0 0.75rem;
    }
  }
}

.fiveChannel {
  justify-content: flex-start;
}

.blocks__swiper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  transition: transform 0.3s ease-out;
}

@media screen and (min-width: 480px) {
  .channelContainer .entriesWrapper {
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-around;
  }
}

@include respond-to($md-up) {
  .entriesWrapper {
    display: flex;
    position: relative;
  }

  .componentHeading {
    padding: 3.75rem 0;
  }
}

@include respond-to($lg-up) {
  .channelContainer {
    max-width: 1100px;

    .entriesWrapper {
      .container__channel {
        padding: 0 0.781rem;
        width: min-content;
      }
    }
  }
}

@include respond-to($xl-up) {
  .channelContainer {
    max-width: 1380px;
  }
}
