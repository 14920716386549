
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@import '~@vestiaire/design-system/scss/_variables';

.editingAppSheet {
  &__deleteIcon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    display: inline-block;
  }

  &__btn {
    font-size: 0.875rem;
    color: $vc-color-system-red50;
    width: 100%;
    padding: 1rem;
    border: 1px solid $vc-color-white-off;
    border-radius: 0.5rem;
    text-align: left;
  }
}
