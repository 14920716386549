
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@mixin imgcover {
  object-fit: cover;
}

.post {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 1rem 1.25rem 1rem;
  width: 100%;
  min-height: 234px;
  height: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;

  &--link {
    display: block;
    height: 100%;
    text-decoration: none;
    color: $vc-color-black-base;

    &:hover {
      .post__text__title {
        text-decoration: underline;
        color: $vc-color-black-dark;
      }
    }
  }

  &__img {
    line-height: 0;
    margin: 0 -1rem 1.125rem -1rem;

    img {
      width: 100%;
      height: auto;
      min-width: 256px;
      min-height: 213px;
      max-height: 213px;
      @include imgcover;
    }
  }

  &__tag {
    display: inline-block;
    margin-bottom: 0.625rem;
    padding: 0.125rem 0.25rem 0.0625rem 0.25rem;
    background-color: $vc-color-grey-light;
    border-radius: 4px;
    font-size: 10px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  &__text {
    height: 94px;
    position: relative;
    overflow: hidden;

    &__title {
      height: auto;
      max-height: 3rem;
      margin-bottom: 0.25rem;
      overflow: hidden;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__intro {
      font-size: 14px;
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__date {
    margin-top: 0.5rem;
    color: $vc-color-grey-dark;
    font-size: 12px;
    line-height: 18px;
    min-height: 18px;
  }
}

@include respond-to($sm-down) {
  .post {
    &__img {
      margin: 0 -1rem 1.125rem 0;
    }
  }
}

@include respond-to($lg-up) {
  .post {
    min-height: 370px;
    padding: 0 1.5rem 1.5rem 1.5rem;

    &__img {
      position: relative;
      margin: 0 -1.5rem 1.625rem -1.5rem;
      line-height: 0;
      overflow: hidden;
      height: 0;
      width: auto;
      padding-top: calc(296 / 356 * 100%);
      img {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-width: auto;
        transform: translateX(-50%);
      }
    }

    &__text {
      height: 108px;

      &__title {
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin-bottom: 0.5rem;
      }

      &__intro {
        font-size: 1rem;
        line-height: 1.5rem;

        em {
          font-style: italic;
        }
      }

      &__date {
        margin-top: 1rem;
      }
    }
  }
}
