
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
$padding: 16px;

.wysiwyg {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 4px;
  text-align: center;
  font-family: $vc-font-body;
  font-size: 0.875rem;
  overflow-wrap: break-word;

  @include respond-to($lg-up) {
    font-size: 1rem;
  }

  ol,
  ul {
    list-style: disc;
  }

  em {
    font-style: italic;
  }

  h1,
  h2,
  h3,
  h4 {
    display: block;
    line-height: 1;
    font-family: $vc-font-headings;
    font-weight: $vc-font-headings-weight;
    color: $vc-color-text-base;
  }

  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  @include respond-to($lg-up) {
    h1 {
      font-size: 3.375rem;
    }

    h2 {
      font-size: 2.25rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.5rem;
    }
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
  }

  // added
  .rubriques {
    -webkit-font-smoothing: antialiased;
    fill: currentColor;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    overflow-wrap: break-word;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    cursor: inherit;
    padding: 13px 0 0;
    margin: 0 0 30px;
    text-align: center;
    color: #bf9f56;
    font-size: 18px;
    font-family: Didot;
  }

  figure {
    img {
      width: 100%;
    }
  }

  // added
  .edito {
    &__header {
      text-align: center;
    }

    &__headerLead {
      -webkit-font-smoothing: antialiased;
      fill: currentColor;
      cursor: inherit;
      border: 0;
      font: inherit;
      text-align: center;
      font-size: 18px !important;
      font-family: Georgia, Palatino, 'Times New Roman', Times;
      color: #000;
      max-width: 685px;
      line-height: 28px !important;
      padding: 35px 0;
      display: block;
      margin: auto;
    }
    &__citation {
      display: block;
      width: 100%;
      max-width: 685px;
      margin: auto;
      padding: 35px 0;
      line-height: 40px;
      font-size: 36px;
      font-family: Didot, Georgia;
      font-style: italic;
      color: #000;
      text-align: center;
    }
    &__subtitle {
      text-align: center;
    }
  }

  article {
    &.text {
      -webkit-font-smoothing: antialiased;
      fill: currentColor;
      font-size: 100%;
      max-width: 685px;
      margin: auto;
      display: block;
      padding: 35px 0;
      p {
        font-size: 16px !important;
      }
    }
  }

  .btnWhite {
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    box-sizing: border-box;
    margin: 0;
    font: inherit;
    text-rendering: geometricPrecision;
    vertical-align: baseline;
    overflow-wrap: break-word;
    text-decoration: none;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    transition: 0.3s;
    font-family: 'HK Grotesk', sans-serif;
    font-weight: 600;
    line-height: 1;
    border-radius: 4px;
    background: #1e1e1e;
    border: 1px solid #1e1e1e;
    color: #fff;
    font-size: 12px;
    height: 32px;
    padding: 0 16px;
  }

  .tAc {
    text-align: center;
  }
}

@include respond-to($lg-up) {
  .text {
    padding: 32px 142px;
  }
}

@include respond-to($xl-up) {
  .text {
    padding: 32px 284px;
  }
}

.textcontainer {
  &_cta_module {
    padding: 16px 0 0;

    &_cta {
      justify-content: center;
      min-width: 268px;
      margin-bottom: 1rem;

      a {
        width: 100%;
      }
    }
  }
}

@include respond-to($md-up) {
  .textcontainer {
    &_cta_module {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &_cta {
        display: flex;
        justify-content: center;
        width: auto;
        min-width: auto;

        a {
          width: 100%;
        }
      }
    }

    .textcontainer_cta_module_cta + .textcontainer_cta_module_cta {
      margin-left: 1rem;
    }
  }
}
