
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@import '~@vestiaire/design-system/scss/_variables';

.textSwatch {
  display: flex;
  padding: 10px;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  border: 1px solid #ebebeb;
  border-radius: 0.5rem;
  background: #fff;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s;
}

.checked {
  background-color: $vc-orange;
  color: white;
}
